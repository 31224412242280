import React, { useState } from "react";
import { UserContext } from "./UserContext";

export const UserProvider = ({ children }) => {
  const storedUser = JSON.parse(localStorage.getItem("user"));
  const sheet_id = storedUser ? storedUser.sheet_id : null;
  const user_id = storedUser ? storedUser.user_id : null;
  const first_name = storedUser ? storedUser.first_name : null;
  const last_name = storedUser ? storedUser.last_name : null;
  const is_admin = null;
  const is_manager = null;
  const [user, setUser] = useState({
    user_id: user_id,
    sheet_id: sheet_id,
    first_name: first_name,
    last_name: last_name,
    is_admin: is_admin,
    is_manager: is_manager,
  });

  const setUserViaResponse = (response) => {
    setUser(() => ({
      user_id: response.user_id,
      sheet_id: response.sheet_id,
      first_name: response.first_name,
      last_name: response.last_name,
      is_admin: response.is_admin,
      is_manager: response.is_manager,
    }));
  };

  const setAdmin = (isAdmin) => {
    setUser((currentUser) => ({
      ...currentUser,
      is_admin: isAdmin,
    }));
  };

  const setManager = (isManager) => {
    setUser((currentUser) => ({
      ...currentUser,
      is_manager: isManager,
    }));
  };

  return (
    <UserContext.Provider
      value={{ user, setUser, setUserViaResponse, setAdmin, setManager }}
    >
      {children}
    </UserContext.Provider>
  );
};
