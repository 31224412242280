import React, { useState } from "react";
import { SheetUserContext } from "./SheetUserContext";

export const SheetUserProvider = ({ children }) => {
  const [sheetUser, setSheetUser] = useState({
    sheet_user_id: null,
    sheet_id: null,
    first_name: null,
    last_name: null,
  });

  const setSheetUserViaResponse = (response) => {
    setSheetUser(() => ({
      sheet_user_id: response.user_id,
      sheet_id: response.sheet_id,
      first_name: response.first_name,
      last_name: response.last_name,
    }));
  };

  return (
    <SheetUserContext.Provider
      value={{ sheetUser, setSheetUser, setSheetUserViaResponse }}
    >
      {children}
    </SheetUserContext.Provider>
  );
};
