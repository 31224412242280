import axios from "axios";

const VERSION = "v1";
const BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/${VERSION}`;
const SHEETS_ENDPOINT = `${BASE_URL}/sheets/:sheet_id`;
const SHEET_COMPETENCIES_ENDPOINT = `${SHEETS_ENDPOINT}/sheet_competencies/:sheet_competency_id`;
const COMPETENCIES_ENDPOINT = `${BASE_URL}/competencies/:competency_id`;
const LEARNING_POINTER_ENDPOINT = `${BASE_URL}/learning_pointers/:learning_pointer_id`;
const COMPETENCY_LEARNING_POINTER_ENDPOINT = `${BASE_URL}/competency_learning_pointers/:competency_learning_pointer_id`;
const COMPETENCY_CHANGE_ENDPOINT = `${SHEET_COMPETENCIES_ENDPOINT}/competency_changes/:competency_change_id`;
const MANAGER_COMPETENCY_CHANGE_ENDPOINT = `${COMPETENCY_CHANGE_ENDPOINT}/manager_competency_changes/:manager_competency_change_id`;
const USER_LEARNING_POINTER_ENDPOINT = `${SHEETS_ENDPOINT}/user_learning_pointers/:user_learning_pointer_id`;
const USERS_ENDPOINT = `${BASE_URL}/users/:user_id`;
const USER_TECHNOLOGIES_ENDPOINT = `${SHEETS_ENDPOINT}/user_technologies/:user_technology_id`;
const TECHNOLOGY_ENDPOINT = `${BASE_URL}/technologies/:technology_id`;
const EMPLOYEE_MANAGER_ENDPOINT = `${BASE_URL}/employee_managers/:manager_id`;
const AREA_ENDPOINT = `${BASE_URL}/areas/:area_id`;
const ROLES_ENDPOINT = `${BASE_URL}/roles/:role_id`;
const SHEET_ROLES_ENDPOINT = `${BASE_URL}/sheet_roles/:sheet_role_id`;

function cleanseUrl(url, path = {}) {
  const urlParts = url.split(BASE_URL);
  if (urlParts.length < 2) {
    return url;
  }
  let urlPath = urlParts[1];
  for (const [key, value] of Object.entries(path)) {
    urlPath = urlPath.replace(`:${key}`, value);
  }
  urlPath = urlPath.replace(/:\w+\b/g, "");
  return BASE_URL + urlPath;
}

/**
 * options is the parent hash that contains the following:
 * path is used to replace path_parameters in the URL, ex: path: { competency_id: # }
 * params is used for query parameters (which would appear after a ? in the URL),
 * data is used for the request body.
 */
function makeRequest(url, options = {}) {
  const method = options.method || "get";
  const params = options.params || {};
  const data = options.data || {};

  if (
    !["get", "post", "patch", "put", "delete"].includes(method.toLowerCase())
  ) {
    console.error("Invalid REST method specified");
    return;
  }
  
  const config = {
    method: method,
    url: cleanseUrl(url, options.path || {}),
    headers: { Authorization: `Bearer ${localStorage.getItem("cm_token")}` },
    params: params,
    data: data,
  };

  return new Promise((resolve, reject) => {
    axios(config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 403 &&
          window.location.pathname !== "/"
        ) {
          window.location.href = window.location.origin;
        }
        reject(error);
      });
  });
}

const API = {
  usersRequest(options = {}) {
    return makeRequest(USERS_ENDPOINT, options).then((response) => {
      if (options.params?.current_user === true) {
        localStorage.setItem("user", JSON.stringify(response));
      }
      return response;
    });
  },

  sheetsRequest(options = {}) {
    return makeRequest(SHEETS_ENDPOINT, options);
  },

  sheetCompetenciesRequest(options = {}) {
    if (!options.path?.hasOwnProperty("sheet_id")) {
      console.error("sheet_id is a required option");
      return;
    }
    return makeRequest(SHEET_COMPETENCIES_ENDPOINT, options);
  },

  userTechnologiesRequest(options = {}) {
    if (!options.path?.hasOwnProperty("sheet_id")) {
      console.error("sheet_id is a required option");
      return;
    }
    return makeRequest(USER_TECHNOLOGIES_ENDPOINT, options);
  },

  competenciesRequest(options = {}) {
    return makeRequest(COMPETENCIES_ENDPOINT, options);
  },

  rolesRequest(options = {}) {
    return makeRequest(ROLES_ENDPOINT, options);
  },

  sheetRolesRequest(options = {}) {
    return makeRequest(SHEET_ROLES_ENDPOINT, options);
  },

  technologiesRequest(options = {}) {
    return makeRequest(TECHNOLOGY_ENDPOINT, options);
  },

  learningPointersRequest(options = {}) {
    return makeRequest(LEARNING_POINTER_ENDPOINT, options);
  },

  competencyLearningPointersRequest(options = {}) {
    return makeRequest(COMPETENCY_LEARNING_POINTER_ENDPOINT, options);
  },

  userLearningPointersRequest(options = {}) {
    if (!options.path?.hasOwnProperty("sheet_id")) {
      console.error("sheet_id is a required option");
      return;
    }
    return makeRequest(USER_LEARNING_POINTER_ENDPOINT, options);
  },

  competencyChangeRequest(options = {}) {
    if (!options.path?.hasOwnProperty("sheet_id")) {
      console.error("sheet_id is a required option");
      return;
    }

    if (!options.path?.hasOwnProperty("sheet_competency_id")) {
      console.error("sheet_competency_id is a required option");
      return;
    }
    return makeRequest(COMPETENCY_CHANGE_ENDPOINT, options);
  },

  managerCompetencyChangeRequest(options = {}) {
    if (!options.path?.hasOwnProperty("sheet_id")) {
      console.error("sheet_id is a required option");
      return;
    }

    if (!options.path?.hasOwnProperty("sheet_competency_id")) {
      console.error("sheet_competency_id is a required option");
      return;
    }

    if (!options.path?.hasOwnProperty("competency_change_id")) {
      console.error("competency_change_id is a required option");
      return;
    }
    return makeRequest(MANAGER_COMPETENCY_CHANGE_ENDPOINT, options);
  },

  areasRequest(options = {}) {
    return makeRequest(AREA_ENDPOINT, options);
  },

  employeeManagerRequest(options = {}) {
    return makeRequest(EMPLOYEE_MANAGER_ENDPOINT, options);
  },
};

export default API;
