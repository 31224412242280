import React from "react";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import Modal from "react-modal";
import { UserProvider } from "./contexts/UserProvider";

import { GoogleOAuthProvider } from "@react-oauth/google";
import { SheetUserProvider } from "./contexts/SheetUserProvider";

const container = document.getElementById("root");
const root = createRoot(container);
Modal.setAppElement("#root");

root.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID}>
    <React.StrictMode>
      <BrowserRouter>
        <UserProvider>
          <SheetUserProvider>
            <App />
          </SheetUserProvider>
        </UserProvider>
      </BrowserRouter>
    </React.StrictMode>
  </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
