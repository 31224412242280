import { GoogleLogin } from "@react-oauth/google";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import API from "../api/api";
import { UserContext } from "../contexts/UserContext";

const Login = () => {
  const navigate = useNavigate();
  const { setUserViaResponse } = useContext(UserContext);
  const responseMessage = (response) => {
    localStorage.setItem("cm_token", response.credential);
    API.usersRequest({ params: { current_user: true } })
      .then((response) => {
        setUserViaResponse(response);
        navigate("/sheet");
      })
      .catch((error) => {
        console.error("user request error: ", error);
        alert("failed to retrieve user");
      });
  };

  const errorMessage = (error) => {
    console.error(error);
  };

  return (
    <div className="container">
      <div className="content">
        <h1>Solution Street - Competency Matrix</h1>
        <GoogleLogin onSuccess={responseMessage} onError={errorMessage} />
        <br />
      </div>
    </div>
  );
};

export default Login;
